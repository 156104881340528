<template>
  <div>
    <el-link href="https://stagesvideos.s3.cn-north-1.amazonaws.com.cn/Stages+Quick+Start+Guide+%231+-+Login+%26+Change+Password.mp4" target="_blank">How to use Stages</el-link>
  </div>
</template>
<script>
export default {
  name: "Video"
}
</script>
<style scoped>

</style>
